// ** React Imports
import { useState, createContext, useEffect } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
import globalEn from '@src/utility/intl/en.json'
import globalEs from '@src/utility/intl/es.json'
import globalBr from '@src/utility/intl/br.json'


// ** Dashboard Language Data
import homeEn from '@src/app/home/intl/en.json'
import homeEs from '@src/app/home/intl/es.json'
import homeBr from '@src/app/home/intl/br.json'

import combinedIndicatorEs from '@src/app/combinedIndicators/intl/es.json'
import combinedIndicatorEn from '@src/app/combinedIndicators/intl/en.json'
import combinedIndicatorPtBR from '@src/app/combinedIndicators/intl/pt-BR.json'

import galleryEs from '@src/app/galleryDisplay/intl/es.json'
import galleryEn from '@src/app/galleryDisplay/intl/en.json'
import galleryPtBr from '@src/app/galleryDisplay/intl/br.json'

import incidentsDashboardBr from "@src/app/dashboard/intl/pt-BR.json"
import incidentsDashboardEs from "@src/app/dashboard/intl/es.json"
import incidentsDashboardEn from "@src/app/dashboard/intl/en.json"

import incidentsEn from "@src/app/incidents/intl/en.json"
import incidentsEs from "@src/app/incidents/intl/es.json"
import incidentsPtBR from "@src/app/incidents/intl/pt-BR.json"

import trackerEn from "@src/app/tracker/intl/en.json"
import trackerEs from "@src/app/tracker/intl/es.json"
import trackerPtBr from "@src/app/tracker/intl/pt-BR.json"

import loginEn from "@src/app/login/intl/en.json"
import loginEs from "@src/app/login/intl/es.json"
import loginPtBr from "@src/app/login/intl/pt-BR.json"

import modulesEn from "@src/app/modules/intl/en.json"
import modulesEs from "@src/app/modules/intl/es.json"
import modulesPtBr from "@src/app/modules/intl/pt-BR.json"

import { flattenMessages } from './flattenMessages'

// ** Menu msg obj
const menuMessages = {
  en: { ...globalEn, ...homeEn, ...combinedIndicatorEn, ...galleryEn, ...incidentsDashboardEn, ...incidentsEn, ...trackerEn, ...loginEn, ...modulesEn },
  es: { ...globalEs, ...homeEs, ...combinedIndicatorEs, ...galleryEs, ...incidentsDashboardEs, ...incidentsEs, ...trackerEs, ...loginEs, ...modulesEs},
  pt: { ...globalBr, ...homeBr, ...combinedIndicatorPtBR, ...galleryPtBr, ...incidentsDashboardBr, ...incidentsPtBR, ...trackerPtBr, ...loginPtBr, ...modulesPtBr}
}

// ** Create Context
const Context = createContext()

const localeLang = localStorage.getItem('locale')

const IntlProviderWrapper = ({ children }) => {
  // ** States
  const [locale, setLocale] = useState('es')
  const [messages, setMessages] = useState(flattenMessages(menuMessages['es']))

  useEffect(() => {
    if (localeLang) {
      setLocale(localeLang)
      setMessages(flattenMessages(menuMessages[localeLang]))
    }
  }, [])

  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(flattenMessages(menuMessages[lang]))
  }
  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='es'>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
